import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
      "path": "/appshell",
      "component": r => require.ensure([], () => r(require('../views/Appshell.vue')), 'appshell'),
      "meta": {},
      "name": "appshell"
  },
  {
      "path": "/",
      "component": r => require.ensure([], () => r(require('../views/Index.vue')), 'index'),
      "meta": {},
      "children": [
          {
              "path": "",
              "component": r => require.ensure([], () => r(require('../views/index/Index.vue')), 'index-index'),
              "meta": {},
              "children": [
                  {
                      "path": "admin/billing-payment-term",
                      "component": r => require.ensure([], () => r(require('../views/index/index/admin/billing-payment-term/index.vue')), 'admin--billing-payment-term'),
                      "meta": {},
                      "name": "indexIndexAdminBilling-payment-term"
                  },
                  {
                      "path": "admin/contract-review",
                      "component": r => require.ensure([], () => r(require('../views/index/index/admin/contract-review/Index.vue')), 'admin--contract-review'),
                      "meta": {},
                      "name": "indexIndexAdminContract-review"
                  },
                  {
                      "path": "admin/wbs",
                      "component": r => require.ensure([], () => r(require('../views/index/index/admin/wbs/index.vue')), 'admin--wbs'),
                      "meta": {},
                      "name": "indexIndexAdminWbs"
                  },
                  {
                      "path": "admin/vendor-cc",
                      "component": r => require.ensure([], () => r(require('../views/index/index/admin/vendor-cc/index.vue')), 'admin--admin/vendor-cc'),
                      "meta": {},
                      "name": "indexIndexAdminVendorCc"
                  },
                  {
                      "path": "approval",
                      "component": r => require.ensure([], () => r(require('../views/index/index/approval/Index.vue')), 'approval'),
                      "meta": {},
                      "name": "indexIndexApproval"
                  },
                  {
                      "path": "ci/wbs/code-pool",
                      "component": r => require.ensure([], () => r(require('../views/index/index/ci/wbs/code-pool/index.vue')), 'ci-wbs--code-pool'),
                      "meta": {},
                      "name": "indexIndexCiWbsCode-pool"
                  },
                  {
                      "path": "ci/wbs/details/:token",
                      "component": r => require.ensure([], () => r(require('../views/index/index/ci/wbs/details/_token.vue')), 'ci-wbs--details--token'),
                      "meta": {},
                      "name": "indexIndexCiWbsDetailsToken"
                  },
                  {
                      "path": "ci/wbs",
                      "component": r => require.ensure([], () => r(require('../views/index/index/ci/wbs/Index.vue')), 'ci-wbs'),
                      "meta": {},
                      "name": "indexIndexCiWbs"
                  },
                  {
                      "path": "ci/wbs/list",
                      "component": r => require.ensure([], () => r(require('../views/index/index/ci/wbs/list/index.vue')), 'ci-wbs-list'),
                      "meta": {},
                      "name": "indexIndexCiWbsList"
                  },
                  {
                      "path": "ci/wbs/retired",
                      "component": r => require.ensure([], () => r(require('../views/index/index/ci/wbs/retired/index.vue')), 'ci-wbs-retired'),
                      "meta": {},
                      "name": "indexIndexCiWbsRetired"
                  },
                  {
                      "path": "",
                      "component": r => require.ensure([], () => r(require('../views/index/index/Index.vue')), 'index-index-index'),
                      "meta": {},
                      "name": "index"
                  },
                  {
                      "path": "legal/business-cl",
                      "component": r => require.ensure([], () => r(require('../views/index/index/legal/business-cl/index.vue')), 'legal--business-cl'),
                      "meta": {},
                      "name": "indexIndexLegalBusiness-cl"
                  },
                  {
                      "path": "legal/request/details/client-project/:token",
                      "component": r => require.ensure([], () => r(require('../views/index/index/legal/request/details/client-project/_token.vue')), 'legal--request-details--client-project--token'),
                      "meta": {},
                      "name": "indexIndexLegalRequestDetailsClient-projectToken"
                  },
                  {
                      "path": "legal/request/details/other/:token",
                      "component": r => require.ensure([], () => r(require('../views/index/index/legal/request/details/other/_token.vue')), 'legal--request-details--other--token'),
                      "meta": {},
                      "name": "indexIndexLegalRequestDetailsOtherToken"
                  },
                  {
                      "path": "legal/request/details/vendor/:token",
                      "component": r => require.ensure([], () => r(require('../views/index/index/legal/request/details/vendor/_token.vue')), 'legal--request-details--vendor--token'),
                      "meta": {},
                      "name": "indexIndexLegalRequestDetailsVendorToken"
                  },
                  {
                      "path": "legal/request/details/gfk-vendor/:token",
                      "component": r => require.ensure([], () => r(require('../views/index/index/legal/request/details/gfk-vendor/_token.vue')), 'legal--request-details--gfk-vendor--token'),
                      "meta": {},
                      "name": "indexIndexLegalRequestDetailsGfkVendorToken"
                  },
                  {
                      "path": "legal/request/list",
                      "component": r => require.ensure([], () => r(require('../views/index/index/legal/request/list/Index.vue')), 'legal--request-list'),
                      "meta": {},
                      "name": "indexIndexLegalRequestList"
                  },
                  {
                      "path": "legal/template",
                      "component": r => require.ensure([], () => r(require('../views/index/index/legal/template/index.vue')), 'legal--template'),
                      "meta": {},
                      "name": "indexIndexLegalTemplate"
                  },
                  {
                      "path": "legal/training-corner",
                      "component": r => require.ensure([], () => r(require('../views/index/index/legal/training-corner/index.vue')), 'legal--training-corner'),
                      "meta": {},
                      "name": "indexIndexLegalTraining-corner"
                  },
                  {
                      "path": "mylab",
                      "component": r => require.ensure([], () => r(require('../views/index/index/mylab/index.vue')), 'index-index-mylab'),
                      "meta": {},
                      "name": "indexIndexMylab"
                  },
              ]
          },
          {
              "path": "login",
              "component": r => require.ensure([], () => r(require('../views/index/Login.vue')), ''),
              "meta": {},
              "name": "indexLogin"
          },
          {
              "path": "to-live",
              "component": r => require.ensure([], () => r(require('../views/index/ToLive.vue')), ''),
              "meta": {},
              "name": "indexToLive"
          },
          {
              "path": "upgrading",
              "component": r => require.ensure([], () => r(require('../views/index/Upgrading.vue')), ''),
              "meta": {},
              "name": "indexUpgrading"
          }
      ]
  },
  {
      "path": "/my-msal",
      "component": r => require.ensure([], () => r(require('../views/my-msal/index.vue')), ''),
      "meta": {},
      "name": "my-msal"
  },
  {
      "path": "/error",
      "component": r => require.ensure([], () => r(require('../views/Error.vue')), ''),
      "meta": {},
      "name": "error",
      "alias": "*"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
